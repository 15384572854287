import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Pricing from '../views/Pricing.vue'
import ContactUs from '../views/ContactUs.vue'
import PrivacyPolicy from "@/views/PrivacyPolicy";
import TermsOfUse from "@/views/TermsOfUse";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: Pricing
  },
  {
    path: '/contact',
    name: 'contact',
    component: ContactUs
  },
  {
    path: '/terms-of-use',
    name: 'Terms Of Use',
    component: TermsOfUse
  },
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    component: PrivacyPolicy
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
