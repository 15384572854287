import Vue from 'vue';
import Vuex from 'vuex';
import auth from '@/store/modules/auth';
import pricing from '@/store/modules/pricing';
import contactUs from "@/store/modules/contactUs";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    auth: auth,
    pricing: pricing,
    contactUs,
  }
})
