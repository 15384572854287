import { http } from '../../../api'

const pricing = {
  namespaced: true,
  state: {
    pricing: [],
  },
  actions: {
    getPlans: ({commit}) => {
      return http.get('/plans').then((res) => {
        commit('getPlans', res.data)
      })
    },
  },
  mutations: {
    getPlans(state,data){
      state.pricing = data.data
    }
  }
};

export default pricing
