<template>
  <header>
    <nav>
      <div class="bloom-mobile-header" :class="openMenu ? 'nav-visible' : ''">
        <router-link tag="div" to="/" class="logo">
          <img src="../assets/images/logo.png"/>
        </router-link>
        <div class="toggle-nav">
          <i class="material-icons ico-menu" @click="openMenu = true" id="openNavButton">menu</i>
          <i class="material-icons ico-close" @click="openMenu = false" id="closeNavButton">close</i>
        </div>
        <ol class="nav">
          <li class="item">
            <router-link tag="a" class="nav-bar-link" to="/about">About</router-link>
          </li>
          <li class="item">
            <router-link tag="a" class="nav-bar-link" to="/pricing">Pricing</router-link>
          </li>
          <li class="item">
            <router-link tag="a" class="nav-bar-link" to="/contact">Contact Us</router-link>
          </li>
          <li class="item">
            <router-link tag="a" class="nav-bar-link" to="/privacy-policy">Privacy Policy</router-link>
          </li>
          <li class="item">
            <router-link tag="a" class="nav-bar-link" to="/terms-of-use">Terms Of Use</router-link>
          </li>
          <li class="item">
            <a :href="loginUrl" class="nav-bar-link"> Login </a>
          </li>
          <li class="item">
            <button type="button" class="free-trial-button">Start free trial</button>
          </li>
        </ol>
      </div>
      <div class="container">
        <router-link tag="div" to="/" class="logo">
          <img src="../assets/images/logo.png" alt="fast-share" class="logo">
        </router-link>
        <ul class="nav-bar">
          <li>
            <router-link class="nav-bar-link" to="/about">About</router-link>
          </li>
          <li>
            <router-link class="nav-bar-link" to="/pricing">Pricing</router-link>
          </li>
          <li>
            <router-link class="nav-bar-link" to="/contact">Contact Us</router-link>
          </li>
          <li>
            <router-link class="nav-bar-link" to="/privacy-policy">Privacy Policy</router-link>
          </li>
          <li>
            <router-link class="nav-bar-link" to="/terms-of-use">Terms Of Use</router-link>
          </li>
          <li>
            <a :href="loginUrl" class="nav-bar-link"> Login </a>
          </li>
          <li>
            <button type="button" class="free-trial-button">Start free trial</button>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
  export default {
    name: 'Header',
    data () {
      return {
        openMenu: false,
        loginUrl: `${process.env.VUE_APP_MAIN_FRONT_URL}/login`,
      }
    },
  }
</script>

<style scoped lang="scss">
   @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
  .bloom-mobile-header {
    display: none;
    height: 74px;
    width: 100%;
    background-color: #fff;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    position: fixed;
    z-index: 10;
    transition: all 0.3s;
    top: 0;
    left: 0;

    @media (max-width: 900px) {
      display: flex;
      justify-content: flex-end;
    }

    &.nav-visible {
      div.toggle-nav {
        i.material-icons {
          &#openNavButton {
            transform: scale(0) rotate(720deg);
          }
          &#closeNavButton {
            transform: scale(1) rotate(0deg);
          }
        }
      }
      .nav {
        left: 0;
        .item {
          opacity: 1;
        }
      }
    }


    div.toggle-nav {
      height: 74px;
      width: 86px;
      padding: 0;
      user-select: none;
      display: flex;
      flex-direction: column;
      align-items: center;
      i.material-icons {
        color: #212121;
        font-size: 28px;
        line-height: 72px;
        cursor: pointer;
        position: absolute;
        transition: transform 0.3s;
        &#openNavButton {
          transform: scale(1) rotate(0deg);
        }
        &#closeNavButton {
          transform: scale(0) rotate(720deg);
        }
      }
    }

    .nav {
      margin: 0;
      width: 35%;
      max-width: 500px;
      height: 100vh;
      position: absolute;
      top: 0;
      left: -100vw;
      display: flex;
      gap: 5px;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      transition: left 0.3s;
      z-index: 99999;
      background-color: #fff;
      padding: 45px 0 0 0;
      box-sizing: border-box;
      box-shadow: 0 0 25px grey;

      .item {
        position: relative;
        text-align: center;
        width: 90%;
        transition: width 0.3s, margin 0.3s;
        /*border-bottom: 1px solid #BDBDBD;*/
        opacity: 0;
        list-style-type: none;
        a {
          color: #160637;
          font-size: 16px;
          font-weight: 300;
          line-height: 52px;
          width: 100%;
          text-transform: uppercase;
          text-decoration: none;
          cursor: pointer;
          transition: .5s;
          &:hover, &:focus {
            color: #d1d1d1;
          }
        }
      }
    }

    .logo {
      box-sizing: border-box;
      align-items: center;
      display: flex;
      justify-content: center;
      line-height: 74px;
      height: 74px;
      font-size: 24px;
      color: #212121;
      font-weight: 400;
      opacity: 1;
      width: calc(100% - 180px);
      margin-right: 0;
      padding: 20px 0;
      background: transparent;
      cursor: pointer;
      img {
        height: 100%;
      }
    }

    .search-container {
      height: 74px;
      width: 74px;
      align-items: center;
      display: flex;
      justify-content: center;
      i {
        color: #212121;
        font-size: 28px;
        line-height: 72px;
        cursor: pointer;
        position: absolute;
        transition: transform 0.3s;
      }
    }

  }

  .container {
    padding: 19px 240px 0 240px;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1400px) {
        padding: 19px 100px 0;
    }
    @media (max-width: 1300px) {
      padding: 19px 100px 0;
    }

    @media (max-width: 1100px) {
        padding: 20px;
    }

    @media (max-width: 900px) {
      padding: 19px 50px 0;
    }

    @media (max-width: 768px) {
      display: none;
    }

  }

  .logo {
    object-fit: contain;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .nav-bar {
    list-style-type: none;
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    gap: 40px;
    @media (max-width: 1100px) {
        gap: 20px;
    }
  }


  li .nav-bar-link {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #000000;
    text-decoration: none;

    transition: .4s;
    &:hover {
      color: #722ED1;
    }

  }

  .free-trial-button {
    padding: 12px 24px;
    background: #FFFFFF;
    border: 1px solid #722ED1;
    border-radius: 23px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #722ED1;
    transition: .5s;

    &:hover {
      color: #FFFFFF;
      background: #722ED1;
    }

  }
</style>
