import { http } from '../../../api'

const auth = {
  namespaced: true,
  actions: {
    register: ( _, params) => {
      return http.post('/sign-up', params);
    },
  },
};

export default auth
