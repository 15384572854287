<template>
 <div class="pricing">
   <div class="pricing-banner">
     <div class="pricing-banner-info">
       <p class="pricing-banner-info-title">Plans & Pricing</p>
       <p class="pricing-banner-info-description">Whether your time-saving automation needs are large or small, we’re here to help you scale.</p>
     </div>
     <div class="pricing-banner-cards">
       <div v-for="(plan, index) in pricing" :key="index" class="pricing-banner-cards-card">
         <div class="pricing-banner-cards-card-body">
           <div class="pricing-banner-cards-card-price">
             <p>$ {{plan.price}}</p>
             <p>/{{plan.period}}</p>
           </div>
           <p class="pricing-banner-cards-card-title">{{plan.plan}}</p>
           <p class="pricing-banner-cards-card-description">{{plan.description}}</p>
           <div  v-for="(feature, index) in plan.plan_features" :key="index" class="pricing-banner-cards-card-options">
             <img src="../assets/images/icon/check-circle.svg" alt="">
             <p>{{feature}}</p>
           </div>
         </div>
         <button class="pricing-banner-cards-card-button">Choose plan</button>
       </div>
     </div>
   </div>
 </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'

  export default {
    name: "Pricing",
    data() {
      return {}
    },
    computed: {
      ...mapState({
        pricing: state => state.pricing.pricing,
      })
    },
    created() {
      this.getPlans().then(() => {
        console.log('pricing', this.pricing)
      })
    },
    methods: {
      ...mapActions({
        getPlans: 'pricing/getPlans',
      }),
    }
  }
</script>

<style scoped lang="scss">
.pricing {
  height: 1219px;

  @media (max-width: 920px) {
    height: auto;
  }

  &-banner {
    /*background: linear-gradient(180deg, #FFFFFF 0%, #E2F1FF 100%);*/
    background-image: url("../assets/images/banner-bg.png");
    background-size: cover;
    padding: 140px 25%;
    position: relative;
    height: 60%;
    @media (max-width: 1400px) {
      padding: 140px 100px;
    }

    @media (max-width: 1024px) {
      background-repeat: no-repeat;
      padding: 130px 10px;
    }

    @media (max-width: 920px) {
      background-size: 100% 514px;
    }

    &-info {
      @media (max-width: 1024px) {
        margin-bottom: 25px;
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      &-title {
        font-weight: 700;
        font-size: 48px;
        line-height: 56px;
        color: #000000;
        margin-bottom: 24px;
        @media (max-width: 1500px) {
          font-size: 40px;
        }
        @media (max-width: 1024px) {
          text-align: center;
          font-size: 32px;
        }
        @media (max-width: 768px) {
          font-size: 25px;
          line-height: 1.2;
        }

      }

      &-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 24px;
        @media (max-width: 1024px) {
          text-align: center;
        }
      }
    }

    &-cards {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 160px;
      /*position: absolute;*/

      @media (max-width: 920px) {
        flex-direction: column;
        margin-top: 36px;
      }

      &-card {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background: #FFFFFF;
        box-shadow: 0px 42px 34px rgba(82, 67, 194, 0.295755);
        border-radius: 26px;
        padding: 20px 30px;

        @media (max-width: 920px) {
          margin-bottom: 60px;
        }

        &:nth-child(1), &:nth-child(3) {
          min-height: 473px;
        }

        &:nth-child(2) {
          min-height: 592px;
          margin-left: 70px;
          margin-right: 70px;

          @media (max-width: 920px) {
            margin-left: auto;
            margin-right: auto;
          }
        }

        &-body {
          max-width: 232px;
        }

        &-price {
          display: flex;
          align-items: end;
          color: #000000;
          margin-bottom: 20px;

          p {
            &:first-child {
              font-size: 36px;
              font-weight: 700;
              line-height: 35px;
            }

            &:last-child {
              font-weight: 500;
              font-size: 17px;
              line-height: 20px;
            }
          }
        }

        &-title {
          font-weight: 500;
          font-size: 28px;
          line-height: 33px;
          color: #000000;
          margin-bottom: 15px;
        }

        &-description {
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          margin-bottom: 26px;
        }

        &-options {
          display: flex;
          margin-bottom: 8px;
          p {
            font-weight: 500;
            font-size: 15px;
            line-height: 18px;
            margin-left: 10px;
          }
        }

        &-button {
          cursor: pointer;
          background: #722ED1;
          border-radius: 23px;
          padding: 12px 24px;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #FFFFFF;
          border: #722ED1 1px solid;
          transition: .5s;

          &:hover {
            color: #722ED1;
            background: #FFFFFF;
          }
        }
      }
    }
  }
}
</style>
