<template>
  <div class="container">
    <div class="footer">
      <div class="footer-col">
         <img class="footer-col-logo" src="../assets/images/logo.png" alt="">
         <p class="footer-col-text">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat ut wisi enim ad minim</p>
         <div class="footer-col-social">
           <a href="">
             <img src="../assets/images/icon/facebook-icon.svg" alt="">
           </a>
           <a href="">
             <img src="../assets/images/icon/twitter.svg" alt="">
           </a>
           <a href="">
             <img src="../assets/images/icon/instagram.svg" alt="">
           </a>
         </div>
       </div>
      <div class="footer-col">
        <p class="footer-col-title">Product</p>
        <a href="#" class="footer-col-link">Connections</a>
        <a href="#" class="footer-col-link">Protocols</a>
        <a href="#" class="footer-col-link">Personas</a>
        <a href="#" class="footer-col-link">Integrations</a>
        <a href="#" class="footer-col-link">Catalog</a>
        <a href="#" class="footer-col-link">Pricing</a>
        <a href="#" class="footer-col-link">GDPR</a>
      </div>
      <div class="footer-col">
        <p class="footer-col-title">For Developers</p>
        <a href="#" class="footer-col-link">Docs</a>
        <a href="#" class="footer-col-link">API</a>
        <a href="#" class="footer-col-link">Open Source</a>
        <a href="#" class="footer-col-link">Engineering Team</a>
      </div>
      <div class="footer-col">
        <p class="footer-col-title">Company</p>
        <a href="#" class="footer-col-link">Careers</a>
        <a href="#" class="footer-col-link">Blog</a>
        <a href="#" class="footer-col-link">Press</a>
      </div>
      <div class="footer-col">
        <p class="footer-col-title">Support</p>
        <a href="#" class="footer-col-link">Help Center</a>
        <a href="#" class="footer-col-link">Contact Us</a>
        <a href="#" class="footer-col-link">Security</a>
        <a href="#" class="footer-col-link">Bulletins</a>
        <a href="#" class="footer-col-link">Documentation</a>
        <a href="#" class="footer-col-link">Partner</a>
      </div>
      <div class="footer-col">
        <p class="footer-col-title">Newsletter</p>
        <div class="footer-col-form">
          <input type="text" placeholder="Email">
          <img class="footer-col-form-button" src="../assets/images/icon/ionic-ios-send.svg" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "Footer"
  }
</script>

<style scoped lang="scss">
  .container {
    background: #FBFCFF;
    padding: 60px 25px;
    .footer {
      display: flex;
      justify-content: space-between;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      gap: 40px;

      @media (max-width: 1536px) {
        max-width: 1536px;
      }
      @media (max-width: 1280px) {
        max-width: 1280px;
      }
      @media (max-width: 1024px) {
        max-width: 1024px;
        flex-wrap: wrap;
      }
      @media (max-width: 768px) {
        max-width: 768px;
      }
      @media (max-width: 640px) {
        max-width: 640px;
      }

      &-col {
        display: flex;
        flex-direction: column;

        &-logo {
          max-width: 100px;
          min-width: 100px;
          margin-bottom: 20px;
        }

        &-text, &-link {
          max-width: 210px;
          font-weight: 500;
          font-size: 12px;
          line-height: 20px;
          color: #160637;
          margin-bottom: 20px;
          text-decoration: none;

        }

        &-link {
          transition: .4s;
          &:hover {
            color: #722ED1;
          }
        }

        &-title {
          font-weight: 700;
          font-size: 16px;
          line-height: 19px;
          color: #160637;
          margin-bottom: 20px;
        }

        &-social {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 24px;
          max-width: 90px;
        }

        &-form {
          display: flex;
          align-items: center;
          padding: 12px 16px;
          background: #FFFFFF;
          box-shadow: 4px 4px 12px rgba(137, 137, 162, 0.122);

          &-button {
            cursor: pointer;
          }

          input {
            border: none;
            outline: none;
          }

        }


      }

    }
  }

</style>
