import { http } from '../../../api'

const contactUs = {
  namespaced: true,
  actions: {
    sendContact: ( _, params) => {
      return http.post('/contact-us', params);
    },
  },
};

export default contactUs
