<template>
 <div class="contact">
   <div class="contact-banner">
     <div class="contact-banner-info">
       <p class="contact-banner-info-title">Contact Us</p>
       <p class="contact-banner-info-description">Any question or remarks? Just write us a message!</p>
     </div>
     <div class="contact-banner-form">
       <div class="contact-banner-form-information">
         <p class="contact-banner-form-information-title">Contact Information</p>
         <p class="contact-banner-form-information-description">Say something to start a live chat!</p>
         <div class="contact-banner-form-information-info">
           <img src="../assets/images/icon/phone-call.svg" alt="">
           <a href="tel:+374 94 444 444">+374 94 444 444</a>
         </div>
         <div class="contact-banner-form-information-info">
           <img src="../assets/images/icon/email.svg" alt="">
           <a href="mailto: demo@gmail.com">demo@gmail.com</a>
         </div>
         <div class="contact-banner-form-information-info">
           <img src="../assets/images/icon/location.svg" alt="">
           <p>132 Dartmouth Street Boston, Massachusetts 02156 United States</p>
         </div>
         <div class="contact-banner-form-information-social">
           <img src="../assets/images/icon/twitter-w.svg" alt="">
           <img src="../assets/images/icon/instagram-w.svg" alt="">
           <img src="../assets/images/icon/fb-w.svg" alt="">
         </div>
       </div>
       <form class="contact-banner-form-body" @submit.prevent="submitForm" autocomplete="off">
         <div class="contact-banner-form-body-inputs">
           <input type="text" placeholder="Your First Name*" v-model="formData.first_name" required>
           <input type="text" placeholder="Your Last Name*" v-model="formData.last_name" required>
           <input type="email" placeholder="Your Email*" v-model="formData.email" required>
           <input type="text" placeholder="Subject" v-model="formData.subject">
         </div>
         <textarea
           class="contact-banner-form-body-textarea"
           placeholder="Message*"
           v-model="formData.message"
           minlength="10"
           maxlength="50"
           required
         >
         </textarea>
         <div class="contact-banner-form-body-button">
           <button :disabled="disabledSendMessage">Send Message</button>
         </div>
       </form>
     </div>
   </div>
 </div>
</template>

<script>
  import { mapActions } from 'vuex';

  export default {
    name: "contact",
    data() {
      return {
        formData: {
          first_name: '',
          last_name: '',
          email: '',
          subject: '',
          message: '',
        },
        errors: {
          password: '',
          confirmPassword: '',
        },
        disabledSendMessage: false,
      };
    },
    methods: {
      ...mapActions({
        sendContact: 'contactUs/sendContact'
      }),
      submitForm() {
        console.log(this.formData);
        this.disabledSendMessage = true;
        this.sendContact(this.formData).then((res) => {
          console.log(res);
          this.$alert("Your contact information has been sent successfully", "Success", 'success').then(() => {
            this.formData = {
              first_name: '',
                last_name: '',
                email: '',
                subject: '',
                message: '',
            };
          });
        }).catch(err => {
          if (err.response.data) {
            const message = Object.values(err.response.data)[0][0];
            this.$alert(message, "Error", 'error');
          } else {
            this.$alert(err.response.data.message, "Error", 'error');
          }
        }).finally(() => {
          this.disabledSendMessage = false;
        });
      },
    }
  }
</script>

<style scoped lang="scss">
.contact {
  height: 1219px;

  @media (max-width: 920px) {
    height: auto;
  }

  &-banner {
    /*background: linear-gradient(180deg, #FFFFFF 0%, #E2F1FF 100%);*/
    background-image: url("../assets/images/banner-bg.png");
    background-size: cover;
    padding: 140px 25%;
    position: relative;
    height: 60%;
    @media (max-width: 1400px) {
      padding: 140px 100px;
    }

    @media (max-width: 1024px) {
      background-repeat: no-repeat;
      padding: 130px 10px;
    }

    @media (max-width: 920px) {
      background-size: 100% 514px;
    }

    &-info {
      text-align: center;

      &-title {
        font-weight: 700;
        font-size: 48px;
        line-height: 56px;
        color: #000000;
        margin-bottom: 24px;
        @media (max-width: 1500px) {
          font-size: 40px;
        }
        @media (max-width: 1024px) {
          text-align: center;
          font-size: 32px;
        }
        @media (max-width: 768px) {
          font-size: 25px;
          line-height: 1.2;
        }

      }

      &-description {
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #000000;
        opacity: 0.5;
        margin-bottom: 24px;
        @media (max-width: 1024px) {
          text-align: center;
        }
      }
    }

    &-form {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      margin-top: 57px;
      /*position: absolute;*/
      border-radius: 10px;
      /*height: 667px;*/


      @media (max-width: 920px) {
        flex-direction: column;
        margin-top: 36px;
      }

      &-information {
        background-image: url("../assets/images/content-form-bg.png");
        background-size: cover;
        padding: 40px;
        max-width: 490px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        /*height: 100%;*/

        @media (max-width: 920px) {
          max-width: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 10px;
        }


        &-title {
          font-weight: 700;
          font-size: 28px;
          line-height: 33px;
          color: #FFFFFF;
          margin-bottom: 15px;

          @media (max-width: 620px) {
            font-weight: 600;
            font-size: 20px;
            line-height: 23px;
          }
        }

        &-description {
          font-weight: 400;
          font-size: 18px;
          line-height: 21px;
          color: #C9C9C9;
          margin-bottom: 115px;

          @media (max-width: 620px) {
            margin-bottom: 18px;
            font-weight: 400;
            font-size: 11px;
            line-height: 13px;
          }
        }

        &-info {
          display: flex;
          align-items: center;
          margin-bottom: 55px;

          @media (max-width: 620px) {
            flex-direction: column;
            align-items: center;
            text-align: center;
          }

          a, p {
            font-weight: 400;
            font-size: 16px;
            line-height: 19px;
            color: #FFFFFF;
            text-decoration: none;
          }

          img {
            margin-right: 25px;

            @media (max-width: 620px) {
              margin-right: 0;
              margin-bottom: 14px;
            }
          }

        }

        &-social {
          display: flex;
          align-items: center;
          margin-top: 160px;

          img {
            margin-right: 24px;
          }
          @media (max-width: 620px) {
            margin-top: 77px;
          }

        }

      }

      &-body {
        background: #FFFFFF;
        box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
        border-radius: 10px;
        padding: 80px 30px 44px;

        &-inputs {
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;

          input {
            margin-bottom: 20px;
            padding: 16px;
            border: 1px solid #D9D9D9;
            border-radius: 4px;
            outline: none;
            width: 47%;
            /*height: 51px;*/

            @media (max-width: 620px) {
              width: 100%;
            }
          }
        }

        &-textarea {
          margin-bottom: 20px;
          padding: 16px;
          border: 1px solid #D9D9D9;
          border-radius: 4px;
          outline: none;
          width: 100%;
          height: 264px;
        }

        &-button {
          text-align: right;
          margin-top: 87px;

          button {
            cursor: pointer;
            background: #722ED1;
            border-radius: 5px;
            padding: 12px 24px;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #FFFFFF;
            border: #722ED1 1px solid;
            transition: .5s;

            &:hover {
              color: #722ED1;
              background: #FFFFFF;
            }
          }
        }

      }


    }
  }
}
</style>
