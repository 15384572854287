import axios from 'axios';

export const baseURL = process.env.VUE_APP_API_URL;

const http = axios.create({
  baseURL,
  withCredentials: false,
});

export {
  http
}
