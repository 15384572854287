<template>
  <div>
    <div class="main">
      <div class="main-banner">
        <div class="main-banner-info">
          <p class="main-banner-info-title">
            Post to 10+ social networks with our API and easily manage your content with our user-friendly
            dashboard.
          </p>
          <p class="main-banner-info-description">We are providing api and dashboard for </p>
          <button class="main-banner-info-button">Start free trial</button>
        </div>
        <div class="main-banner-image">
          <img src="../assets/images/banner-img.png" alt="">
        </div>
      </div>
      <div class="main-about main-container">
        <button class="main-about-button">
          <img class="main-about-button-image" src="../assets/images/icon/awesome-eye.svg"
               alt="Why FastShare">
          <span>Why FastShare</span>
        </button>
        <p class="main-about-title">Get actionable insights from Big Data in 3 steps</p>
        <p class="main-about-description">Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam
          nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</p>
        <div class="main-about-steps">
          <div class="main-about-steps-item">
            <img class="main-about-steps-item-img" src="../assets/images/icon/chart-bar-33.svg"
                 alt="Valuable business insights">
            <p class="main-about-steps-item-title">Valuable business insights</p>
            <p class="main-about-steps-item-description">Collect processed & cleansed data that is ready to
              be analyzed to gather valuable business insights.</p>
          </div>
          <div class="main-about-steps-item">
            <img class="main-about-steps-item-img" src="../assets/images/icon/newsletter-dev.svg"
                 alt="Powerful Algorithms">
            <p class="main-about-steps-item-title">Powerful Algorithms</p>
            <p class="main-about-steps-item-description">With the help of powerful algorithms, quality rules
              & techniques, obtain simplified & enriched data.</p>
          </div>
          <div class="main-about-steps-item">
            <img class="main-about-steps-item-img" src="../assets/images/icon/timeline.svg"
                 alt="Data in real-time">
            <p class="main-about-steps-item-title">Data in real-time</p>
            <p class="main-about-steps-item-description">Collect data in real-time from multiple channels
              and move it into a data lake, in its original format.</p>
          </div>
        </div>
        <div class="main-social">
          <div class="main-social-img">
            <img src="../assets/images/social.png" alt="">
          </div>
          <div class="main-social-info">
            <button class="main-social-info-button">
              <img src="../assets/images/icon/productTeams.svg" alt="For Product Teams">
              <span>For Product Teams</span>
            </button>
            <p class="main-social-info-title">Available 10 social medias</p>
            <p class="main-social-info-description">A centralized platform that integrates zillions of data
              sources using Big Data ELT (Extract, Load & Transform) that leaves no data behind</p>
          </div>
        </div>
        <div class="main-social">
          <div class="main-social-info">
            <button class="main-social-info-button">
              <img src="../assets/images/icon/compass.png" alt="For Product Teams">
              <span>For Product Teams</span>
            </button>
            <p class="main-social-info-title">Data-driven pipelines in minutes</p>
            <p class="main-social-info-description">Maintenance-free data pipelines with quick set-up and
              straight-forward deployments that are powered by a modern & scalable platform.</p>
          </div>
          <div class="main-social-img">
            <img src="../assets/images/server.png" alt="">
          </div>
        </div>
      </div>
      <div class="main-partner">
        <div class="main-partner-container">
          <p class="main-partner-title">"What I love about Qubly is the easy way we can collaborate even if
            there is a lot of people involved in the process"</p>
          <div class="main-partner-person">
            <div class="main-partner-person-item">
              <img class="main-partner-person-item-avatar" src="../assets/images/Profile.png" alt="">
              <p class="main-partner-person-item-name">Guillaume Cabane CTO @ BigSpring</p>
            </div>
          </div>
          <div class="main-partner-company">
            <div class="main-partner-company-item">
              <img src="../assets/images/icon/Tonkean.svg" alt="">
            </div>
            <div class="main-partner-company-item">
              <img src="../assets/images/icon/BigSpring.svg" alt="">
            </div>
            <div class="main-partner-company-item">
              <img src="../assets/images/icon/Clearbit.svg" alt="">
            </div>
            <div class="main-partner-company-item">
              <img src="../assets/images/icon/Mine.svg" alt="">
            </div>
            <div class="main-partner-company-item">
              <img src="../assets/images/icon/Shake.svg" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="main-auth main-container">
        <div class="main-auth-technology">
          <p class="main-auth-technology-title">See why the world’s best companies use <span>Fast Share</span>
            to become truly data-driven.</p>
          <div class="main-auth-technology-line">
            <div></div>
            <p class="main-auth-technology-line-text">Trusted by</p>
            <div></div>
          </div>
          <div class="main-auth-technology-method">
            <div class="main-auth-technology-method-item">
              <img src="../assets/images/icon/accenture-4.svg" alt="">
            </div>
            <div class="main-auth-technology-method-item">
              <img src="../assets/images/icon/react.svg" alt="">
            </div>
            <div class="main-auth-technology-method-item">
              <img src="../assets/images/icon/airbnb.svg" alt="">
            </div>
            <div class="main-auth-technology-method-item">
              <img src="../assets/images/icon/sky-4.svg" alt="">
            </div>
          </div>
        </div>
        <div class="main-auth-form">
          <form class="main-auth-form-body" @submit.prevent="submitForm" autocomplete="off">
            <p class="main-auth-form-body-title">Sign Up</p>
            <input v-model="formData.name" class="main-auth-form-body-input" placeholder="Your name" type="text" required>
            <input v-model="formData.email" class="main-auth-form-body-input" placeholder="Your primary email" type="email" required>
            <input
              v-model="formData.password"
              :class="`main-auth-form-body-input ${errors.password && 'main-auth-form-body-input-error'}`"
              placeholder="Password"
              type="password"
              required
            >
            <p class="main-auth-form-body-validation-message" v-if="errors.password">{{errors.password}}</p>
            <input
              v-model="formData.confirmPassword"
              :class="`main-auth-form-body-input ${errors.confirmPassword && 'main-auth-form-body-input-error'}`"
              placeholder="Re-type Password"
              type="password"
              required
            >
            <p class="main-auth-form-body-validation-message" v-if="errors.confirmPassword">{{errors.confirmPassword}}</p>
            <div class="main-auth-form-body-line"></div>
            <p class="main-auth-form-body-text">Already have an account? <a v-if="loginUrl" :href="loginUrl">Login</a></p>
            <button class="main-auth-form-body-button" :disabled="disabledSignUp">Sign Up</button>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapActions } from 'vuex';
  export default {
    name: 'Home',
    data() {
      return {
        formData: {
          name: '',
          email: '',
          password: '',
          confirmPassword: '',
        },
        errors: {
          password: '',
          confirmPassword: '',
        },
        loginUrl: `${process.env.VUE_APP_MAIN_FRONT_URL}/login`,
        disabledSignUp: false,
      };
    },
    methods: {
      ...mapActions({
        register: 'auth/register'
      }),
      submitForm() {
        if (this.validateForm()) {
          this.disabledSignUp = true;
          this.register(this.formData).then(() => {
              this.$alert("You have successfully registered", "Success", 'success').then(() => {
                this.formData = {
                    name: '',
                    email: '',
                    password: '',
                    confirmPassword: '',
                };
                window.open(`${process.env.VUE_APP_MAIN_FRONT_URL}/login`);
              });
            }).catch(err => {
              if (err.response.data) {
                const message = Object.values(err.response.data)[0][0];
                this.$alert(message, "Error", 'error');
              } else {
                this.$alert(err.response.data.message, "Error", 'error');
              }
            }).finally(() => {
              this.disabledSignUp = false;
            });
        }
      },
      validateForm() {
        if (this.formData.password.length < 8) {
          this.errors.password = 'Password must be at least 8 characters long';
          return false
        }
        this.errors.password = '';
        if (this.formData.confirmPassword !== this.formData.password) {
          this.errors.confirmPassword = 'Passwords must match';
          return false
        }
        this.errors.confirmPassword = '';
        return true
      }

    }
  }
</script>

<style scoped lang="scss">
  .main {

    &-container {
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
      width: 100%;

      @media (max-width: 1536px) {
        max-width: 1536px;
        padding: 0 40px;
      }
      @media (max-width: 1280px) {
        max-width: 1280px;
      }
      @media (max-width: 1024px) {
        max-width: 1024px;
      }
      @media (max-width: 768px) {
        max-width: 768px;
      }
      @media (max-width: 640px) {
        max-width: 640px;
      }

    }

    &-banner {
      /*background: linear-gradient(180deg, #FFFFFF 0%, #E2F1FF 100%);*/
      background-image: url("../assets/images/banner-bg.png");
      background-size: cover;
      display: flex;
      justify-content: space-between;
      padding: 140px 240px 225px 240px;

      @media (max-width: 1400px) {
        padding: 140px 100px 225px 100px;
      }

      @media (max-width: 1024px) {
        flex-direction: column-reverse;
        padding: 130px 10px;
        align-items: center;
      }
      @media (max-width: 1100px) {
        padding: 20px;
      }

      &-info {
        max-width: 800px;
        @media (max-width: 1024px) {
          margin-bottom: 25px;
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        &-title {
          font-weight: 700;
          font-size: 48px;
          line-height: 56px;
          color: #000000;
          margin-bottom: 24px;
          @media (max-width: 1500px) {
            font-size: 40px;
          }
          @media (max-width: 1024px) {
            text-align: center;
            font-size: 32px;
          }
          @media (max-width: 768px) {
            font-size: 25px;
            line-height: 1.2;
          }

        }

        &-description {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #000000;
          opacity: 0.5;
          margin-bottom: 24px;
          @media (max-width: 1024px) {
            text-align: center;
          }
        }

        &-button {
          cursor: pointer;
          background: #722ED1;
          border-radius: 23px;
          padding: 12px 24px;
          font-weight: 500;
          font-size: 14px;
          line-height: 16px;
          color: #FFFFFF;
          border: #722ED1 1px solid;
          transition: .5s;

          &:hover {
            color: #722ED1;
            background: #FFFFFF;
          }
        }

      }

      &-image {
        max-width: 630px;
        min-width: 400px;

        img {
          width: 100%;
        }

        @media (max-width: 1024px) {
          min-width: auto;
        }
      }


    }

    &-about {
      margin-top: 112px;
      display: flex;
      align-items: center;
      flex-direction: column;

      &-button {
        background: #F7F1FF;
        border-radius: 26px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #722ED1;
        display: flex;
        align-items: center;
        padding: 11px 16px;
        border: #F7F1FF 1px solid;
        margin-bottom: 24px;

        &-image {
          margin-right: 12px;
        }
      }

      &-title {
        max-width: 60%;
        font-weight: 700;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        margin-bottom: 24px;
        color: #160637;

        @media (max-width: 768px) {
          font-size: 25px;
        }
      }

      &-description {
        max-width: 60%;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #8989A2;
        margin-bottom: 24px;
      }

      &-steps {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 768px) {
          flex-direction: column;
        }

        &-item {
          text-align: center;

          &-img {
            min-width: 45px;
            min-height: 45px;
            margin-bottom: 16px;
          }

          &-title, &-description {
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
          }

          &-title {
            color: #160637;
            margin-bottom: 16px;
          }

          &-description {
            color: #8989A2;
          }

        }

      }

    }

    &-social {
      display: flex;
      justify-content: space-between;
      margin-top: 120px;
      align-items: center;
      width: 100%;

      @media (max-width: 1024px) {
        flex-direction: column;
        margin-top: 60px;
      }


      &-img {
        @media (max-width: 1024px) {
          margin-bottom: 20px;
        }

        img {
          width: 100%;
          pointer-events: none;
        }
      }


      &-info {
        max-width: 470px;

        &-title {
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
          color: #160637;
          margin-bottom: 24px;
        }

        &-description {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #8989A2;
        }

        &-button {
          cursor: pointer;
          background: #F7F1FF;
          border-radius: 26px;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: #722ED1;
          display: flex;
          align-items: center;
          padding: 11px 16px;
          border: #F7F1FF 1px solid;
          margin-bottom: 24px;

          img {
            margin-right: 12px;
          }
        }

      }

    }

    &-partner {
      background-image: url("../assets/images/partner-bg.png");
      background-size: cover;
      padding-top: 200px;
      padding-bottom: 180px;

      &-container {
        max-width: 70rem;
        margin-left: auto;
        margin-right: auto;
        width: 100%;
      }

      &-title {
        font-weight: 500;
        font-size: 24px;
        line-height: 32px;
        text-align: center;
        color: #160637;
      }

      &-person {
        display: flex;
        justify-content: center;
        margin-top: 32px;
        margin-bottom: 32px;

        &-item {
          display: flex;
          align-items: center;
          flex-direction: column;

          &-avatar {
            width: 80px;
            min-width: 80px;
            height: 80px;
            min-height: 80px;
            object-fit: cover;
            border-radius: 1000px;
            margin-bottom: 32px;
          }

          &-name {
            max-width: 150px;
            font-weight: 700;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #000000;
          }

        }

      }

      &-company {
        padding: 35px;
        border-top: 1px solid #DEC9FA;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media (max-width: 800px) {
          flex-direction: column;
        }

        &-item {
          margin: 10px 0px;
        }
      }

    }

    &-auth {
      display: flex;
      justify-content: space-between;
      margin-top: 155px;

      @media (max-width: 1024px) {
        flex-direction: column-reverse;
        margin-top: 60px;
        align-items: center;
      }


      &-technology {
        max-width: 550px;

        @media (max-width: 1024px) {
          margin-top: 20px;
        }

        &-title {
          font-weight: 700;
          font-size: 32px;
          line-height: 40px;
          color: #160637;
          margin-bottom: 46px;

          span {
            color: #722ED1;
          }

        }

        &-line {
          display: flex;
          justify-content: space-between;
          align-items: center;

          &-text {
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #8989A2;
            white-space: nowrap;
            margin-right: 100px;
            margin-left: 100px;
          }

          div {
            width: 100%;
            border: 1px solid #D6D6D6;
          }

        }

        &-method {
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          margin-top: 65px;
          @media (max-width: 800px) {
            align-items: center;
            flex-direction: column;
          }

          &-item {
            width: 45%;
            text-align: left;
            margin-bottom: 50px;

            @media (max-width: 1024px) {
              text-align: center;
            }
          }

        }

      }

      &-form {
        background: #F9F0FF;
        padding: 0 25px 113px;


        @media (max-width: 768px) {
          width: 100%;
          display: block;
        }

        &-body {
          width: 413px;
          margin-top: -43px;
          background: #FFFFFF;
          box-shadow: 4px 4px 12px rgba(137, 137, 162, 0.122);
          border-radius: 8px;
          padding: 32px 40px;
          display: flex;
          flex-direction: column;
          align-items: center;

          @media (max-width: 768px) {
            width: 100%;
            display: block;
            margin-top: 20px;
            padding: 10px;
          }

          &-title {
            font-weight: 700;
            font-size: 24px;
            line-height: 32px;
            text-align: center;
            color: #160637;
            margin-bottom: 24px;
          }

          &-input {
            width: 100%;
            padding: 16px;
            border: #8989A2 1px solid;
            margin-bottom: 24px;
            border-radius: 8px;
            outline: none;
          }

          &-input-error {
            border: #ff1900 1px solid;
          }

          &-validation-message {
            margin: 0 0 24px;
            color: #ff1900;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
          }

          &-line {
            width: 100%;
            border-top: 1px solid #8989A2;
            margin-bottom: 16px;
          }

          &-text {
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #8989A2;

            a {
              color: #722ED1;
              cursor: pointer;
            }

          }

          &-button {
            margin-top: 16px;
            width: 100%;
            border-radius: 8px;
            background: #722ED1;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            text-align: center;
            color: #FFFFFF;
            border: #722ED1;
            padding-top: 12px;
            padding-bottom: 12px;
            border: 1px solid #722ED1;
            transition: .5s;

            &:hover {
              color: #722ED1;
              background: #FFFFFF;
            }

          }

        }

      }

    }

  }
</style>
